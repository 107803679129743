<template>
  <b-modal
    id="move-requirement-modal"
    title="Move Requirement"
    size="lg"
    class="p-0"
    lazy
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <h6 class="text-primary mb-0">
        Moving {{titleSuffix}}
      </h6>
      <p class="" style="font-size: 0.9rem">
        Note: All descendents of selected requirements will also be moved.
      </p>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities mb-0"
        style="max-height: 10rem"
      >
        <div class="font-small-3 ml-1 text-body" v-sanitized-html="bodyText" />
      </vue-perfect-scrollbar>

      <h6 class="mt-2 text-primary">
        1. Select target Specification to Move to
      </h6>
      <SpecificationPicker v-model="targetSpecification" />

      <h6 class="mt-2 text-primary mb-0">
        2. Select target Requirement
      </h6>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 20rem"
      >
        <b-table
          responsive
          striped
          hover
          selectable
          show-empty
          empty-text="The selected specification is empty, requirements will be placed from the section 1."
          select-mode="single"
          class="position-relative"
          :fields="requirementTableFields"
          :items="requirementsList"
          @row-selected="rows => targetRequirement = rows[0] && rows[0].id ? rows[0] : null"
        />
      </vue-perfect-scrollbar>

      <h6 class="mt-2 text-primary">
        3. Relationship to target Requirement
      </h6>
      <b-row>
        <b-col>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="relation"
              :disabled="targetRequirement === null"
              :options="['Before', 'After', 'Child']"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        :disabled="loading"
        variant="outline-secondary"
        @click="cancel"
      >
        Discard
      </b-button>
      <b-button variant="success" :disabled="loading" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Moving...
        </span>
        <span v-else>
          Move Requirement
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import axiosIns from '@/libs/axios'
import { computed, ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'MoveRequirementsModal',
  components: {
    VuePerfectScrollbar,
    SpecificationPicker,
  },
  props: {
    selectedRequirements: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const titleSuffix = ref('')
    const bodyText = ref('')
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    const requirementTableFields = [
      { key: 'section', label: 'Section' },
      { key: 'display_id', label: 'Display ID' },
      { key: 'priority', label: 'Priority' },
    ]

    const targetSpecification = ref(null)
    const targetRequirement = ref(null)
    const relation = ref('')

    const requirementsList = ref([])
    watch(targetSpecification, val => {
      requirementsList.value = []
      if (targetSpecification.value !== null) {
        axiosIns
          .get(`/api/v2/specifications/${targetSpecification.value.id}/requirements`)
          .then(({ data }) => {
            Object.entries(data).forEach(entry => {
              requirementsList.value.push(entry[1])
            })
          })
      }
    })

    const onShow = () => {
      targetSpecification.value = null
      targetRequirement.value = null
      relation.value = 'Child'

      if (props.selectedRequirements.length > 1) {
        titleSuffix.value = `${props.selectedRequirements.length} Requirements`
        bodyText.value = props.selectedRequirements.map(req => `${req.display_id} ${req.object_text}`).join('')
      } else {
        titleSuffix.value = `Requirement ${props.selectedRequirements[0].display_id}`
        bodyText.value = props.selectedRequirements[0].object_text
      }
    }

    const onSubmit = () => {
      loading.value = true
      const payload = {
        requirement_ids: props.selectedRequirements.map(req => req.id),
        target_relation_type: targetRequirement.value ? relation.value.toLowerCase() : 'child',
        target_requirement: targetRequirement.value ? targetRequirement.value.id : targetSpecification.value.id,
      }
      store.dispatch('requirements/moveRequirement', payload).then(response => {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: `Moved ${titleSuffix.value}`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        context.root.$bvModal.hide('move-requirement-modal')
        context.emit('on-move')
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      loading,
      titleSuffix,
      bodyText,
      perfectScrollbarSettings,
      requirementTableFields,
      requirementsList,

      targetSpecification,
      targetRequirement,
      relation,

      onShow,
      onSubmit,
    }
  },
}
</script>
