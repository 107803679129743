<template>
  <div>
    <!-- Backward Trace -->
    <div class="font-medium-1 mb-50 d-inline-flex w-100 justify-content-between">
      <div>
        <p class="mb-0">Backward Trace Links</p>
        <span class="font-small-3">Grouped by Specification</span>
      </div>

      <div>
        <b-link
          title="Create a backward trace link to another Requirement"
          class="mr-2"
          @click.stop="$bvModal.show('link-backward-trace-requirement-modal')"
        >
          <feather-icon icon="LinkIcon" class="text-success" />
        </b-link>
        <b-link
          title="Refresh backward trace links"
          class="mr-2"
          @click="loadTrace"
        >
          <feather-icon icon="RefreshCwIcon" class="text-info" />
        </b-link>
      </div>
    </div>
    <app-collapse v-if="traceItems.length">
      <div v-if="!loadingTrace">
        <app-collapse-item v-for="(spec, index) in traceItems" :key="index" title="">
          <template slot="header">
            <div class="flex-column w-100 cursor-default">
              <div class="d-inline-flex align-middle w-100">
                <b-badge class="mr-1" :variant="spec.traces.length > 0 ? 'info' : 'muted'">
                  {{ spec.traces.length ? spec.traces.length : '-' }}
                </b-badge>

                <div class="d-inline-flex justify-content-between w-100">
                  <span class="font-weight-bold">{{ spec.title }}</span>
                </div>
              </div>
            </div>
          </template>
          <list-trace :all-trace-items="spec.traces" />
        </app-collapse-item>
      </div>
      <div v-else>
        <div class="w-100 d-inline-flex justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <h5 class="text-warning mb-2">
              <atom-spinner class="animate-pulse" />
            </h5>
            <h5>
              Loading Backward Trace Links...
            </h5>
          </div>
        </div>
      </div>
    </app-collapse>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No backward trace links
      </span>
    </div>

    <hr class="my-2">

    <!-- Forward Trace -->
    <div class="font-medium-1 mb-50 d-inline-flex w-100 justify-content-between">
      <div>
        <p class="mb-0">Forward Trace Links</p>
        <span class="font-small-3">Grouped by Specification</span>
      </div>

      <div>
        <b-link
          title="Create a forward trace link to another Requirement"
          class="mr-2"
          @click.stop="$bvModal.show('link-forward-trace-requirement-modal')"
        >
          <feather-icon icon="LinkIcon" class="text-success" />
        </b-link>
        <b-link
          title="Refresh forward trace links"
          class="mr-2"
          @click="loadCoverage"
        >
          <feather-icon icon="RefreshCwIcon" class="text-info" />
        </b-link>
      </div>
    </div>
    <app-collapse v-if="coverageItems.length">
      <div v-if="!loadingCoverage">
        <app-collapse-item v-for="(spec, index) in coverageItems" :key="index" title="">
          <template slot="header">
            <div class="flex-column w-100 cursor-default">
              <div class="d-inline-flex align-middle w-100">
                <b-badge class="mr-1" :variant="spec.traces.length > 0 ? 'info' : 'muted'">
                  {{ spec.traces.length ? spec.traces.length : '-' }}
                </b-badge>

                <div class="d-inline-flex justify-content-between w-100">
                  <span class="font-weight-bold">{{ spec.title }}</span>
                </div>
              </div>
            </div>
          </template>

          <list-trace :all-trace-items="spec.traces" />
        </app-collapse-item>
      </div>
      <div v-else>
        <div class="w-100 d-inline-flex justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <h5 class="text-warning mb-2">
              <atom-spinner class="animate-pulse" />
            </h5>
            <h5>
              Loading Forward Trace Links...
            </h5>
          </div>
        </div>
      </div>
    </app-collapse>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No forward trace links
      </span>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import { useRouter } from '@core/utils/utils'
import {
  computed,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import { BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ListTrace from './ListTrace.vue'

export default {
  name: 'SidebarTabTrace',
  directives: { Ripple },
  components: {
    BBadge,
    ListTrace,
    AppCollapseItem,
    AppCollapse,
    AtomSpinner,
  },
  props: {
    reqId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    onMounted(() => { loadTraceAndCoverage() })
    watch(props, val => { loadTraceAndCoverage() })

    const loadingTrace = ref(false)
    const loadingCoverage = ref(false)
    const loadTraceAndCoverage = () => {
      loadTrace()
      loadCoverage()
    }

    const localReqObj = computed(() => props.reqObj)
    const traceItems = ref([])
    const coverageItems = ref([])

    // Load Backward Trace (also known as Trace)
    const loadTrace = () => {
      loadingTrace.value = true
      store.dispatch('requirement/getRequirementTrace', props.reqId)
        .then(response => { traceItems.value = response })
        .finally(() => { loadingTrace.value = false })
    }

    // Load Forward Trace (also known as Coverage)
    const loadCoverage = () => {
      loadingCoverage.value = true
      store.dispatch('requirement/getRequirementCoverage', props.reqId)
        .then(data => { coverageItems.value = data })
        .finally(() => { loadingCoverage.value = false })
    }

    return {
      localReqObj,
      traceItems,
      coverageItems,
      loadingTrace,
      loadingCoverage,
      loadTrace,
      loadCoverage,
    }
  },
}
</script>
