<template>
  <b-modal
  id="import-requirements-modal"
  title="Import Requirements"
  :size="modalSize"
  no-close-on-backdrop
  hide-footer
  @show="onShow"
>
  <form-wizard
    color="#7367F0"
    :title="null"
    :subtitle="null"
    style="box-shadow: none"
    :finish-button-text="finishButtonText"
    back-button-text="Previous"
    @on-complete="bulkAddRequirements"
  >
    <!-- Step One : Select file -->
    <tab-content
      title="Select .csv file"
      icon="feather icon-file-text"
      :before-change="stepTwoPreLogic"
    >
      <b-form-group label="Select a .csv file to import" label-for="filePickerCSV">
        <b-form-file
          id="filePickerCSV"
          v-model="file"
          accept=".csv"
          invalid-feedback="test"
          :state="Boolean(file)"
          @input="fileLinked"
        />
      </b-form-group>
    </tab-content>

    <!-- Step Two : Map CSV columns to Kompozition properties -->
    <tab-content
      title="Column mapping"
      icon="feather icon-link"
    >
      <div v-if="file">
        <div>
          <h5 class="text-primary">Instructions</h5>
          <p>Please review the data before importing to ensure accuracy. This includes:</p>
          <ul>
            <li>
              <span class="text-primary font-weight-bold">Data Validation</span>: Double-check that the import data is correct and formatted as expected to prevent any import issues.</li>
            <li>
              <span class="text-primary font-weight-bold">Column Matching</span>: Confirm that the column names in your CSV match those in Kompozition, which allows for automatic column mapping. Misaligned names may require manual matching.</li>
            <li>
              <span class="text-primary font-weight-bold">Manual Matching Review</span>: For any columns you match manually, verify that they align with the intended Kompozition fields to avoid data mismatches.</li>
          </ul>
          <p>Once you've reviewed and confirmed all matches, click <span class="text-primary font-weight-bold">Import</span> to proceed.</p>
        </div>

        <hr class="my-2">

        <b-row>
          <b-col>
            <div class="d-inline-flex w-100 align-items-bottom">
              <h4 class="text-primary">
                Imported .csv columns
              </h4>
              <b-form-checkbox
                v-model="has_header_row"
                class="ml-2"
                @change="autoBind"
              >
                .csv file has a header row
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col>
            <h4 class="text-primary">
              Kompozition mapping
            </h4>
          </b-col>
        </b-row>

        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettingsMappings"
          style="max-height: 30vh"
        >
          <b-row v-for="({ property, column}, index) in fields" :key="`columns-${index}`" class="mb-1">
            <!-- CSV Data Columns -->
            <b-col>
              <b-input-group>
                <b-form-select
                  :value="column"
                  :options="columns"
                  @input="val => $set(fields[index], 'column', val)"
                />
                <b-input-group-append v-if="!mandatory_properties.includes(property)">
                  <b-button
                    class="px-1"
                    variant="outline-danger"
                    title="Remove column binding"
                    @click="deleteBinding(property)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-danger"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <!-- Kompozition property -->
            <b-col>
              <h5
                v-if="mandatory_properties.includes(property)"
                class="d-inline-flex h-100 align-items-center"
              >
                {{ property }}
              </h5>
              <b-form-select
                v-else
                :value="property"
                :options="properties"
                :disabled="mandatory_properties.includes(property)"
                @input="val => fields[index].property = val"
              />
            </b-col>
          </b-row>
        </vue-perfect-scrollbar>

        <b-button variant="flat-success" size="sm" block @click="addBinding">
          Add property mapping <feather-icon icon="PlusIcon" />
        </b-button>

        <hr class="my-2">

        <h4 class="text-primary text-center">
          Preview
        </h4>

        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          style="max-height: 40vh"
        >
          <b-table
            :items="requirements.slice(0,5)"
          />
        </vue-perfect-scrollbar>
      </div>
    </tab-content>
  </form-wizard>
</b-modal>
</template>

<script>
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BFormGroup } from 'bootstrap-vue'
import {
  FormWizard,
  TabContent,
} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'ImportCsvRequirementsModal',
  components: {
    BFormGroup,
    FormWizard,
    TabContent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      file: null,
      csv_array: [],
      has_header_row: true,
      hidden_properties: ['spec_id', 'specification', 'id'],
      mandatory_properties: ['display_id', 'object_text', 'priority'],
      default_requirement_fields: [],
      requirement_fields: [],
      bulk_create_loading: false,
      modalSize: 'lg',
      fields: [
        {
          property: 'display_id',
          column: null,
        },
        {
          property: 'object_text',
          column: null,
        },
        {
          property: 'priority',
          column: null,
        },
      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      perfectScrollbarSettingsMappings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
        suppressScrollX: true,
      },
    }
  },
  computed: {
    properties() {
      return this.requirement_fields.filter(field => !this.hidden_properties.includes(field)).map(field => Object({
        value: field,
        text: field.replaceAll('_', ' '),
        disabled: this.fields.map(({ property }) => property).includes(field),
      }))
    },
    columns() {
      const mapFunction = (text, value) => (this.has_header_row ? { value, text } : { value, text: `Column - ${value + 1}` })
      if (!this.csv_array[0]) return []
      return [{ value: null, text: 'No Value' }, ...this.csv_array[0].map(mapFunction)]
    },
    requirements() {
      const requirements = []
      this.csv_array.forEach((row, index) => {
        if (this.has_header_row && index === 0) {
          return
        }
        const requirement = {}
        this.fields.forEach(({ property, column }) => {
          requirement[property] = row[column] || null
        })
        requirements.push(requirement)
      })
      return requirements
    },
    finishButtonText() {
      if (this.bulk_create_loading) {
        return 'Importing Requirements...'
      }
      return 'Import Requirements'
    },
  },
  methods: {
    onShow() {
      this.file = null
      this.has_header_row = true
      this.modalSize = 'lg'
      this.fields = [
        {
          property: 'display_id',
          column: null,
        },
        {
          property: 'object_text',
          column: null,
        },
        {
          property: 'priority',
          column: null,
        },
      ]
      this.getRequirementAttributes()
    },
    stepTwoPreLogic() {
      this.fileValidator()
      this.modalSize = 'xl'
      return true
    },
    fileValidator() {
      return new Promise((resolve, reject) => {
        if (this.file) {
          return resolve(true)
        }
        return reject()
      })
    },
    getRequirementAttributes() {
      const { modelId, specId } = router.currentRoute.params
      const params = {
        model: modelId,
        specification: specId,
        user_upload: true,
      }
      this.$http
        .get('/api/v2/requirements/attributes', { params })
        .then(({ data }) => {
          this.requirement_fields = data.attrs
          this.default_requirement_fields = data.attrs
        })
    },
    bulkAddRequirements() {
      const { specId } = router.currentRoute.params
      this.bulk_create_loading = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('fields', JSON.stringify(this.fields))
      formData.append('has_headers', this.has_header_row)
      this.$store.dispatch('requirements/bulkAddRequirement', { formData, specification: specId})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Begun Importing Requirements',
              icon: 'DownloadIcon',
              variant: 'success',
            },
          })
          this.$emit('imported')
          this.$bvModal.hide('import-requirements-modal')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to import requirements',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.bulk_create_loading = false
          this.$emit('imported')
          this.$bvModal.hide('import-requirements-modal')
        })
    },
    autoBind() {
      const fixedProperties = ['display_id', 'object_text', 'priority']
      if (this.has_header_row) {
        // Get unique column texts (trimmed and lowercase) for easier matching
        const columnTexts = this.columns
          .filter(col => col.text)
          .map(col => col.text.trim().toLowerCase())

        // Create a new fields array to enforce order
        const newFields = []

        // Always assign fixed properties first
        fixedProperties.forEach(fixedProp => {
          const col = this.columns.find(col => col.text && col.text.trim().toLowerCase() === fixedProp)
          newFields.push({ property: fixedProp, column: col ? col.value : null })
        })

        // Assign remaining properties
        const usedProperties = new Set(newFields.map(f => f.property)) // Track assigned props
        const props = this.properties.filter(
          ({ value }) => value && !usedProperties.has(value) && columnTexts.includes(value.trim().toLowerCase()), // Ensure uniqueness and existence in CSV
        )

        this.columns.forEach(col => {
          if (!col.text) return // Prevent errors on missing text
          // Check if col.text exists before calling toLowerCase()
          // also trim spaces
          const prop = props.find(({ value }) => value && col.text.trim().toLowerCase() === value.trim().toLowerCase())

          if (prop && !usedProperties.has(prop.value)) {
            newFields.push({ property: prop.value, column: col.value })
            usedProperties.add(prop.value)
          }
        })

        // Trigger reactivity properly... shits the bed without this for some reason
        this.fields = [...newFields]
      } else {
        // Ensure we use columns length - without this we won't match on right
        const totalFields = this.columns.length - 1 // Need the -1 for the No Value to be skipped
        this.fields = new Array(totalFields).fill(null).map(() => ({ property: null, column: null }))

        const usedValues = new Set()

        let index = 0

        // Assign the fixed properties first
        fixedProperties.forEach((prop, i) => {
          if (i < totalFields) {
            this.fields[i] = { property: prop, column: i }
            usedValues.add(prop)
            index++
          }
        })

        // Get the available properties that are not disabled and not already used
        const availableProperties = this.properties
          .filter(prop => !prop.disabled && !usedValues.has(prop.value))
          .map(prop => prop.value)

        availableProperties.forEach((prop, i) => {
          if (index < totalFields) {
            this.fields[index] = { property: prop, column: index }
            usedValues.add(prop)
            index++
          }
        })

        // Assign all the remaining fields
        while (index < totalFields) {
          this.fields[index] = { property: null, column: index }
          index++
        }
        // Trigger reactivity properly... shits the bed without this for some reason
        this.fields = [...this.fields]
      }
    },
    addBinding() {
      const nextProperty = this.requirement_fields.filter(field => !this.hidden_properties.includes(field)).find(field => !this.fields.map(({ property }) => property).includes(field))
      this.fields.push({
        property: nextProperty,
        column: null,
      })
    },
    deleteBinding(delProperty) {
      this.fields = this.fields.filter(({ property }) => property !== delProperty)
    },
    browserSupportFileUpload() {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    },
    fileLinked() {
      if (!this.browserSupportFileUpload()) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The File APIs are not fully supported in this browser!',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      } else {
        const reader = new FileReader()
        if (this.file) {
          reader.readAsText(this.file)
          reader.onload = evt => {
            // Split into lines and remove empty ones + take only the first 5 lines
            // We are doing this in-case of extremely large files. Mess around with the first 5 lines
            // And then handle the file on the back-end with the data we need
            const allLines = evt.target.result.split('\n').filter(line => line.trim() !== '')
            const first5Lines = allLines.slice(0, 5)
            // Convert the first 5 lines into a 2D array (CSV format)
            this.csv_array = first5Lines.map(line => line.split(','))

            // Process header row (if necessary)
            if (this.has_header_row && this.csv_array[0]) {
              const newFields = this.csv_array[0]
                .filter(colNm => this.default_requirement_fields.includes(colNm.toLowerCase())
                  && !this.requirement_fields.includes(colNm.toLowerCase()))
                .map(x => x.toLowerCase())

              this.requirement_fields = [...this.requirement_fields, ...newFields]
              this.autoBind()
            }
          }
        } else {
          this.csv_array = []
        }
      }
    },
  },
}
</script>
